<template>
    <div id="top">
        <div class="header-box">
            <div class="header-div">
                <div class="header-left">
                    <img :src="resData.logoimg" alt="" class="logo">
                </div>
                <div class="header-right">
                    <div style="display:flex;">
                        <swiper ref="swiperDom" :options="schoolMotto" class="schoolMotto" style="width:300px;margin-top: 25px;">
                            <swiper-slide>
                                <img src="static/jr/xiaoxun1.png" alt="">
                            </swiper-slide>
                            <swiper-slide>
                                <img src="static/jr/xiaoxun2.png" alt="">
                            </swiper-slide>
                            <swiper-slide>
                                <img src="static/jr/xiaoxun3.png" alt="">
                            </swiper-slide>
                            <swiper-slide>
                                <img src="static/jr/xiaoxun4.png" alt="">
                            </swiper-slide>
                        </swiper>
                        <div class="search">
                            <input type="input" placeholder="请输入搜索关键字" v-model="inp" @keyup.enter="hrefSearch()">
                            <img src="static/jr/search.png" alt="" class="search-icon" @click="hrefSearch()">
                        </div>
                    </div>
                    <div class="nav-div">
                        <!-- <el-menu :default-active="activeIndex"
                        class="el-menu-demo index-nav"
                        mode="horizontal"
                        @select="handleSelect"
                        background-color="#fff"
                        text-color="#333"
                        active-text-color="#333">
                        <template v-for="(item,index) in nav">
                            <el-submenu :index="(index+1).toString()" :key="index">
                                <template slot="title">{{item.title}}</template>
                                <el-menu-item :index="index+1+'-'+index" v-for="(val,i) in item.stypes" :key="i" @click="hrefUrl(val.tzurl)">{{val.title}}</el-menu-item>
                            </el-submenu>
                        </template>
                        </el-menu> -->
                        <!-- <template v-for="(item,index) in navList">
                            <el-menu-item :key="index" :index="(index+1).toString()" v-if="!item.Navlist_data">{{item.NavName}}</el-menu-item>
                            <el-submenu :index="(index+1).toString()" :key="index" v-else>
                                <template slot="title">{{item.NavName}}</template>
                                <el-menu-item :index="index+1+'-'+index" v-for="(val,i) in item.Navlist_data" :key="i" @click="hrefUrl(val)">{{val.name}}</el-menu-item>
                            </el-submenu>
                        </template>
                        </el-menu> -->
                    </div>
                    <!-- 导航 -->
                    <div style="display: inline-block; width: 100%;height: 100px;position: relative;text-align:right;"  @mouseleave="mouseoutBtn()">
                        <div v-for="(item,i) in nav" :key="i" style="display: inline-block;margin:0 15px;" >
                            <span  v-bind:class="{isCustomer1:isCustomers==i}"  @mouseover="mouseoverBTN(i)" style="display: inline-block;display:flex;">
                                <!-- <a class="isCus1" v-if="isCustomers==i">
                                    <img :src="item.imgYT" alt="">
                                </a>
                                <a class="isCus1" v-else>
                                    <img :src="item.imgNew" alt="">
                                </a> -->
                                <span style="line-height:30px;" @click="tiao(item.title,item.id,'110'),tiao2(item.title,'654','479','开放大学'),tiao3(item.title,'655','465','党建之窗'),oldJr(item.title,item.tzurl)"> {{item.title}}</span>
                            </span>

                            <template  v-if="item.stypes=='' ? false:true">
                                <div v-if="status && isCustomers==i" class="nav-show">
                                <ul style="display:flex;width:630px;background:#2990dd;color:#fff;flex-wrap: wrap;">
                                    <li style="padding:10px;box-sizing:border-box;" v-for="(val,g) in item.stypes" :key="g"  @click="hrefUrl(val.tzurl,val.id,val.pid,val.title)">
                                        {{val.title}}
                                    </li>
                                </ul>
                            </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JrApi from '../../../lvya-common/src/api/eos/jr'
export default {
    name: 'top',
    data() {
        return {
            status: false,
            isCustomers: -1,
            activeIndex: '1',
            resData: '',
            nav: [],
            navLists: [],
            search: '',
            inp: '',
            // ele导航
            navList: [
            {
            NavName: '学校概况',
            Navlist_data: [{
                name: '学校简介',
                url: '/listAll'
                },
                {
                name: '治理机构'
                }]
            },
            {
            NavName: '机构设置',
            Navlist_data: [{
                name: '党政办',
                url: '/subpage'
                },
                {
                name: '工会'
                }]
            },
            {
            NavName: '系部设置',
            Navlist_data: [{
                name: '农业园林'
                },
                {
                name: '电气工程'
                }]
            },
            {
            NavName: '党建之窗',
            //   Navlist_data: [{
            //       name: '选项一'
            //     },
            //     {
            //       name: '选项二'
            //     }]
            },
            {
            NavName: '开放大学',
            Navlist_data: [{
                name: '选项一'
                },
                {
                name: '选项二'
                }]
            },
            {
            NavName: '项目创建',
            Navlist_data: [{
                name: '选项一',
                url: '/projectCreation'
                },
                {
                name: '选项二'
                }]
            }
            ],
            // 之前导航
            list: [{
                name: '学校概况',
                imgYT: require('../../../../public/static/jr/nav00.png'),
                imgNew: require('../../../../public/static/jr/nav0.png'),
                listOl: [
                {
                    name: '学校简介',
                    path: '/remove'
                },
                {
                    name: '治理机构'
                }
                ]
            },
            {
                name: '机构设置',
                imgYT: require('../../../../public/static/jr/nav11.png'),
                imgNew: require('../../../../public/static/jr/nav1.png'),
                listOl: [
                {
                    name: '小食堂'
                }
                ]
            },
            {
                name: '系部设置',
                imgYT: require('../../../../public/static/jr/nav22.png'),
                imgNew: require('../../../../public/static/jr/nav2.png'),
                listOl: [
                {
                    name: '3'
                }
                ]
            },
            {
                name: '党建之窗',
                imgYT: require('../../../../public/static/jr/nav33.png'),
                imgNew: require('../../../../public/static/jr/nav3.png'),
                listOl: [
                {
                    name: '4'
                }
                ]
            },
            {
                name: '开放大学',
                imgYT: require('../../../../public/static/jr/nav44.png'),
                imgNew: require('../../../../public/static/jr/nav4.png'),
                listOl: [
                {
                    name: '5'
                }
                ]
            },
            {
                name: '项目创建',
                imgYT: require('../../../../public/static/jr/nav55.png'),
                imgNew: require('../../../../public/static/jr/nav5.png'),
                listOl: [
                {
                    name: '6'
                }
                ]
            }],
            //   轮播
            schoolMotto: {
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true, // 允许点击小圆点跳转
                // },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false // 手动切换之后继续自动轮播
                },
                loop: true
            },
        }
    },
    created() {
        this.HF()
        this.Nav()
    },
    methods: {
        handleSelect(key, keyPath) {
        },
        mouseoverBTN(item) {
            this.isCustomers = item
            this.status = true
        },
        mouseoutBtn(item) {
            this.isCustomers = -1
            this.status = false
        },
        // 跳转二级页面
        hrefUrl(url, id, pid, tit) {
            if (url == '' || url == null) {
                this.$router.push({ path: '/indexList', query: { id, pid, tit }})
            } else {
                this.$router.push({ path: url, query: { id, tit }})
            }
        },
        hrefSearch(key) {
            this.$router.push({ path: '/search', query: { key: this.inp }})
        },
        tiao(tit, id, pid) {
            if (tit == '项目创建') {
                this.$router.push({ path: '/projectCreation', query: { id, pid }})
            }
        },
        tiao2(tit1, id, pid, tit) {
            if (tit1 == '开放大学') {
                this.$router.push({ path: '/subpage', query: { id, pid, tit }})
            }
        },
        tiao3(tit1, id, pid, tit) {
            if (tit1 == '党建之窗') {
                this.$router.push({ path: '/subpage', query: { id, pid, tit }})
            }
        },
        oldJr(tit1, url) {
            if (tit1 == '旧版网站') {
                window.open(url, '_blank')
                // this.$router.push({ path: '/subpage' })
            }
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
            // console.log(resData)
        },
        async Nav() {
            const resData = await JrApi.Nav()
            this.nav = resData
            // console.log(this.nav)
        }
    },
    mounted() {
    },
}
</script>

<style scope>
.isCustomer1{
  color: #8b1e23;
  cursor: pointer;
  /* //新图片 */
}
.isimg1{
  background: url('../../../../public/static/jr/nav22.png') no-repeat !important;
  background-size: 100% 100%!important;
  display: inline-block;
  width: 25px;
  height: 25px;
}
.isCus1{
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.isCus1 img{
  width: 25px;
  height: 25px;
}
.nav-show{
  position: absolute;
  background: #2990dd;
  width: 630px;
  height: 40px;
  right: 0;
  margin-top: 13px;
  cursor: pointer;
}
#top{
    width: 100%;
}
.header-box{
  width: 100%;
  height: 120px;
  background: #fff;
}
.header-div{
  width:1200px;
  /* height: 80px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.search {
    float: right;
    margin: 20px 0;
    position: relative;
}
.search input {
    width: 190px;
    height: 32px;
    border-radius: 15px;
    border: 1px solid #8b1e23;
    padding-left: 10px;
    outline: none;
    padding-right: 45px;
}
.search-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 6px;
    top: 3px;
}
.logo{
    /* width: 430px;
    height: 88px; */
    margin-top: 15px;
}
.xiaoxun{
    width: auto;
    height: 30px;
    margin-top: 25px;
}
.nav{
    width: 100%;
    /* background: #52070c; */
    margin-top: 60px;
}
.el-menu-demo{
    /* width: 1200px; */
    margin: 0 auto;
    display: flex;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-menu-item{
  padding: 0 20px;
}
.schoolMotto{
    width: 300px;
}
.schoolMotto swiper-slide img{
    width: 100%;
}
.index-nav{
    width: 630px;
    margin-top: -12px;
}
.index-nav .el-menu-item.is-active{
    border-bottom: 2px solid #fff!important;
}
.index-nav .el-submenu.is-active .el-submenu__title{
    border-bottom: 2px solid #fff!important;
}
.index-nav .el-menu-item{
    color: #333;
}
.index-nav .el-submenu .el-submenu__title{
    color: #333;
}
.index-nav .el-menu-item:hover{
    background-color: #fff!important;
}
.index-nav .el-submenu .el-submenu__title:hover{
    background-color: #fff!important;
}
</style>
